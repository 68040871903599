*{
  box-sizing: border-box;

}
body{
  min-width: 1000px;
}
:root {
  --main-bg-color:rgb(240, 235, 222);
  --black-90: hsl(0, 0%, 10%);
  --black-80a: rgba(0, 0, 0, 0.8);
  --detail-width: calc(100vw - 30px*2);
}
h1, h2, h3 {
  font-weight: 400;
}
.Show {
  display: block;
  position: absolute;
}
.Pointer {
  cursor: pointer !important;
}
.Hide {
  display: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*:focus{
  outline: none;
}
button{
  font-family: aktiv-grotesk, sans-serif;
}

.App {
  box-sizing: border-box;
  text-align: left;
  /* height: 100vh; */
  background-color: var(--main-bg-color);
  padding: 0px;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  min-width: 1000px;
}

.App-header-container {
  width: 100%;
  height: 280px;
  overflow: hidden;
  padding: 40px 20px 20px 20px;
  background-color: var(--main-bg-color);
  font-size: calc(10px + 1vmin);
  color: var(--black-90);
  position: fixed;
  top:0;
  z-index: 2;
  transition: height 0.3s, padding 0.3s border 0.3s;
}
.App-header-container.scrolled {
  padding: 10px 20px 0px 20px;
  height: 134px;
  border-bottom: solid 1px rgb(170, 170, 170)

}
.App-header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.AppHeaderLeft{
  width: 46%;
  margin: 10px 20px;
}
.AppHeaderLeft.scrolled{
  margin: 0px 20px 0px;
}
.AppHeaderRight{
  width: 46%;
  font-size: 16px;
  margin: 10px 20px;
  height: 120px;
  transition:visibility 0.3s linear,opacity 0.3s;

}
.AppHeaderRight.scrolled{
  opacity: 0;
  visibility:hidden;
  height: 40px;
  /* display: none; */
  /* font-size: 12px; */
}
.AppHeaderRight p {
  margin: 40px 40px 0px 40px;
  letter-spacing: 0.015em;
  opacity: 0.8;
}

.App-header-title {
  width: 400px;
  height: 80px;
  object-fit: contain;
  /* background-image: url(/logo.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: width 0.3s linear;
}
.App-header-title.scrolled {
  width: 260px;
  height: 70px;
}
.App-header-subtitle {
  margin: 10px 0px 10px 0px;
  font-family: aktiv-grotesk-condensed;
}

#Tagline{
  font-family: aktiv-grotesk-extended;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.Body {
  padding: 310px 40px 220px 40px;
}
.Body.mini {
  padding: 310px 40px 100px 40px;
}
.BrowseBy{
  font-size: 14px;
  margin: 0px 20px;
  font-weight: 500;
  transition: opacity 0.3s linear, height 0.3s;
}
.BrowseBy.scrolled{
  display: none;
  opacity: 0;
  /* visibility:hidden; */
  height: 0px;
}

.Menu{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px 20px 10px 20px;
}
.MenuDivider{
  width: 1px;
  height: 50px;
  border-right: solid 1px rgb(170, 170, 170);
  margin-right: 20px;
  margin-bottom: 10px;
}
.MenuItem {
  margin: 10px 20px 0px 0px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.01em;
  font-weight: 500;
  padding-bottom: 12px;
  opacity: 0.8;
  font-size: 18px;
  transition: border-bottom 0.2s;
}
.Menu-item:hover {
  opacity: 1;
}
.MenuItem.active {
  color: black;
  opacity: 1;
  border-bottom: solid 4px rgb(15, 90, 151);
}

.Tiles {
  background: transparent;
  /* height: calc(100vh - 500px); */
  /* width：100%; */
}
.FooterOutput{
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.Popular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.Popular::-webkit-scrollbar {
  display: none;
}

.ZoomIcons {
  display: inline;
}
.ZoomIcon {
  width: 19px;
  height: 19px;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 4px;
  cursor: pointer;
}
.ZoomIcon:hover {
  opacity: 0.9;
}
.Icon2 {
  background-image: url(/2.svg);
}
.Icon4 {
  background-image: url(/4.svg);
}
.Timeline{
  width:100%;
  overflow: hidden;
}

.TimelineRows{
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  min-height: 640px;
}
.TimelineRows::-webkit-scrollbar {
  width: 6px;
}
.TimelineRows::-webkit-scrollbar-track {
  background-color: var(--black-90);
  border: solid 7px var(--main-bg-color);
}
.TimelineRows::-webkit-scrollbar-thumb {
  background-color: var(--black-90);
}

.TimelineGroup{
  display: flex;
  flex-direction: row;
}
.TimelineDivider{
  margin:10px 20px 30px 20px;
  display: flex;
  flex-flow: column nowrap;
}
.TimelineDividerLine{
  border-left: 1px solid var(--black-90);
  height: 100%;
  min-height: 100px;
  width: 1px;
  margin: auto;
}
.TimelineGroupContent{
  margin:10px 30px 30px 30px;
  padding: 10px 20px 10px 20px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  transition: 0.5s ;
  overflow-y: hidden;
}
.TimelineZoom2{
  grid-template-rows: repeat(2, 1fr);
  --timeline-light-grey: rgba(0, 0, 0, 0.1);
  background: repeating-linear-gradient(
    to right,
    var(--timeline-light-grey) ,
    var(--timeline-light-grey) 1px,
    #ffffff00 1px,
    #ffffff00 9px
  );

}
.TimelineZoom4{
  grid-template-rows: repeat(4, 1fr);
  --timeline-light-grey: rgba(0, 0, 0, 0.08);
  background: repeating-linear-gradient(
    to right,
    var(--timeline-light-grey) ,
    var(--timeline-light-grey) 1px,
    #ffffff00 1px,
    #ffffff00 4px
  );
}

.TileWrapper {
  flex-basis: 20%;
  min-height:120px;
  /* height:40%; */
  min-width: 180px;
  overflow: visible;
  /* position: relative; */
  padding: 0px;
  box-sizing: border-box;
}
.TimelineTile {
  cursor: pointer;
  padding: 20px;
  margin: 0px 4px;
  border: solid 1px transparent;
  transition: background-color 0.2s;
}
.TimelineTile2:hover {
  /* transform: scale(1.01); */
  /* border: solid 1px rgba(0, 0, 0, 0.507); */
  background-color: rgba(255, 255, 255, 0.5);
}
.TimelineTile2 {
  min-width: 320px;
  width: 34vh;
  max-width: 410px;
  cursor: pointer;
  padding: 20px;
  margin: 0px 4px;
  border: solid 1px transparent;
  transition: 0.3s;
}
.TimelineTile4 {
  min-width: 180px;
  width: 17vh;
  height: 14vh;
  max-width: 300px;
  padding: 10px;
  font-size: 13px;

}
.Designer{
  width:100%;
}
.DesignerGroup{
  display: flex;
  flex-direction: row;
  padding: 100px 0px;
  justify-content: space-around
}
.DesignerInfo{
  position: sticky;
  position: -webkit-sticky;
  /* background: white; */
  width: 55%;
  height: 100%;
  max-width: 640px;
  top: 200px;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  color: var(--black-90);
}
.DesignerImgContainer{
  width: 100%;
  max-height: 400px;
  margin-bottom: 8px;
}
.DesignerImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 400px;
}
.DesignerImgCredit{
  font-size: 12.5px;
  display: flex;
  margin-left: auto;
  margin-bottom: 12px;
}
.DesignerImgCredit a{
  color: grey;
}
.DesignerImgCredit p{
  color: grey;
  margin: 0px 4px 20px 0px;
}
.DesignerBio{
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 10px;
}
.DesignerBioName{
  font-size: 22px;
  color: var(--black-90);
  font-weight: 500;
  margin-right: 8px;
}
.DesignerBioYear{
  font-weight: 400;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.4);

}
.DesignerBioContent{
  opacity: 0.9;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.DesignerBioContent a{
  color: black
}
.DesignerBioUrl{
  margin-top: 10px;
  border-radius: 4px;
  width: 161px;
  font-size: 17px;
  display: flex;
  align-items: center;
  font-weight: 500;
  opacity: 0.9;
  transition: 0.2s;
  color: black;
  padding-left: 0px;
  text-decoration: none;
}
.DesignerBioUrl:hover {
  transform: translateY(-1px) translateX(2px);
}

.DesignerTile {
  flex-basis: 100%;
  min-width: 180px;
  max-width: 700px;
  padding: 20px;
  transition: background-color 0.3s;
  border: solid 1px transparent;
  /* cursor: pointer; */
  margin-bottom: 40px;
}
.DesignerTile:hover {
  /* border: solid 1px rgba(0, 0, 0, 0.507); */
  background-color: rgba(134, 134, 134, 0.062);
}
.DesignerGroupContent{
  width: 40%;
  padding: 0px;
}
.TileWrapper2 {
  width: 100%;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.TileWrapperNormal{
  width: 100%;
  padding: 20px;
}
.FooterOutputWrapper{
  padding: 10px;
  transition: background-color 0.2s;
  cursor: pointer;
}
.FooterOutputWrapper:hover {
  /* border: solid 1px rgba(0, 0, 0, 0.507); */
  background-color: rgba(209, 209, 209, 0.144);
}
.Tile {
  width: 100%;
  padding-bottom: 66.6%;
  position: relative;
}
.TileImage {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* cursor: pointer; */
  background-color: rgba(231, 231, 231, 0.473);
}

.LoadingTile {
  width: 100%;
  padding-bottom: 66.6%;
  /* height: 200px; */
  background-color: rgb(214, 214, 214);
  /* position: absolute;
  top: 0; bottom: 0; left: 0; right: 0; */
}

@media(max-width: 1560px) {
  .TileWrapper {
    flex-basis: 25%;
  }
}
@media(max-width: 1280px) {
   .TileWrapper {
    flex-basis: 33.33%;
  }
}
@media(max-width: 815px) {
  .TileWrapper {
    flex-basis: 50%;
  }
}
@media(max-width: 555px) {
  .TileWrapper {
    flex-basis: 100%;
  }
}
.Preview {
  cursor: pointer;
}
.TileWrapper2:hover {
  transform: scale(1.02) translateY(-20px);
  /* transition: 0.5s ; */
  z-index: 1;
  background-color: rgb(252, 252, 252);
  /* color: white */
  /* padding: 20px;
  margin: -20px; */
}
.TileWrapper2:hover .TileDesigner{
  display: block;
}
.TileWrapper2:hover .TileImage{
  border: solid 1px rgb(230, 230, 230);
}

.Timeline4Wrapper{
  padding: 10px;
}

.TileInfo {
  width:100%;
  padding: 10px 0px;
}
.TileInfo.mini {
  padding: 4px 0px 10px;
}
.TileName {
  display: block;
  font-weight: 400;
  font-size: 15.5px;
}
.TileName.mini {
  font-size: 12px;
}
.TileYear {
  display: block;
  opacity: 0.6;
  font-size: 14.5px;
  letter-spacing: -0.01em;
}
.TileDesigner {
  display: block;
  display:none;
  position: absolute;
  opacity: 0.6;
  font-size: 14.5px;
}
.TileDesigner.mini{
  font-size: 12px;
  padding-bottom: 4px;
}
.TileDesignerYear{
  display: block;
  opacity: 0.6;
  font-size: 14.5px;
}

.BackButtonContainer {
  font-size: 24px;
  font-family: aktiv-grotesk-extended;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 32px;
  height: 90px;
  position: fixed;
  width: var(--detail-width);
  z-index: 4;
  transition: height 0.3s, font-size 0.3s;
  justify-content: space-between;
}

.BackButtonContainer.scrolled {
  background-color: rgb(238, 238, 238,0.96);
  font-size: 20px;
  height: 72px;
}
.BackButtonContainer.scrolled.dark {
  background-color: rgba(43, 43, 43, 0.938);
}

.BackButtonContainer:hover {
  transition: 0.5s ;
}

.BackButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  font-weight: 400;
  cursor: pointer;

}
.BackText{
  font-weight: 400;
}
.BackText.dark{
  color: white;
}
.BackButton:hover {
  transform: translateX(-10px);
}

.BackArrow {
  width: 60px;
  margin: 10px 20px 6px 20px;
  transition: width 0.3s ease-in-out;
}
.BackArrow.scrolled {
  width: 48px;
}
.ChangeBGButton{
  font-family: aktiv-grotesk-extended, sans-serif;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid var(--black-90);
  flex-shrink: 0;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: transparent;
}
.ChangeBGButton.dark{
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.ChangeBGButton:hover{
  background-color: hsla(0, 0%, 83%, 0.247);
}
.ChangeBGButton.dark:hover{
  background-color: hsla(0, 0%, 0%, 0.185);
  border: 1px solid rgba(255, 255, 255, 1);
}

#Detail {
  width: var(--detail-width);
  height: calc(100vh - 30px);
  position: fixed;
  background-color: #E3E3E3;
  top: 30px;
  left: 30px;
  z-index: 3;
  overflow-y: scroll;
  animation: DetailMoveUp 0.5s;
}

@keyframes DetailMoveUp {
  0% {transform: translateY(100vh);}
  35% {transform: translateY(100vh);}
  100% {transform: translateY(0vh);}
}


#Detail::-webkit-scrollbar {
  display: none;
}

.Info {
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.DetailImgCol {
  width: 45%;
  margin: 20px;

}
.DetailImg {
  width: 100%;
  height: calc(40vw/4*3);
  object-fit: contain;
  /* background-color: rgba(231, 231, 231, 0.103); */
  /* border: solid 1px rgba(128, 128, 128, 0.322); */
}
.DetailThumbnails{
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
}
.ThumbnailImgContainer{
  width: 15%;
  height: calc((15% - 4px)/4*3 + 4px);
  min-width: 84px;
  min-height: 64px;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ThumbnailImg{
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  min-width: 80px;
  min-height: 60px;
  top:0px;
  left:0px;
  border: solid 1px #e7e7e7;
  cursor: pointer;

  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: content-box;
  /* position: absolute; */
}
.ThumbnailImg:hover{
  border:solid 1px #bdbdbd;
  /* opacity: 0.96; */
}
.Detail-Info-Content {
  width: 45%;
  padding: 20px;
  margin: 20px;
  color: black
}
.Detail-Info-Content a{
  color: black;
  text-decoration: none
}
.Detail-Info-Content.dark {
  color:white;
}
.Detail-Info-Content.dark a{
  color: white
}
.Detail-Info-Name {
  padding: 0px 18px;
  font-size: 39px;
  margin-top: 0px;
  font-weight: 500;
  margin-bottom: 10px;
  opacity: 0.9;
}

.Detail-Info {
  padding: 0px 18px;
  font-size: 17px;
  opacity: 0.5;
  margin-bottom: 40px;
}
.Detail-Info-Description {
  padding: 0px 18px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  opacity: 0.76;
}
.Detail-Info-Url {
  padding: 12px 18px;
  border-radius: 4px;
  width: 161px;
  font-size: 17px;
  display: flex;
  align-items: center;
  font-weight: 500;
  opacity: 0.9;
  transition: 0.2s;
}
.Detail-Info-Url:hover {
  /* opacity: 1; */
  /* background-color: rgba(128, 128, 128, 0.048); */
  transform: translateY(-1px) translateX(2px);
  /* border-bottom: solid 1px black; */
}

.Generator {
  display: block;
  background-color: #ececec;
  position: relative;;
}
.Upper {
  width: 100%;
  /* height: 90vh; */
  max-height: 700px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  position: relative;
  z-index: 1;
  margin-bottom: -100px;
}

.Examples {
  width: 220px;
  margin: 0px 20px 0px 0px;
  padding: 16px 0px;
  background-color: rgb(255, 255, 255);
}

.Example {
  width: 188px;
  height: 125.33px;
  margin: 16px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  border: solid 1px transparent;
  cursor: pointer;
}
.Example.active {
  border: solid 1px rgb(201, 201, 201);;
}
.Example img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Editor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.CanvasColumn {
  width: 100%;
}

.CanvasBorder {
  position: relative;
  width: 100%;
  padding-bottom: 640px;
  max-height: 100%;
  border: solid rgba(255, 255, 255, 0.2) 1px;
  background-color: white;
}
#CanvasContainer {
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-content: center;
  align-items: center;
}
#CanvasWrapper {
  width: 100%;
  background-color: rgb(216, 216, 216);
  border: solid rgb(223, 223, 223) 0.5px;
}
.ResizeButtonContainer {
  /* margin-top: -100px; */
  /* transform: translateY(-100px); */
  position: relative;
  /* z-index: -1; */
  background: #333333;
  height: 220px;
  margin-top: 20px;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: center;
}
.ResizeButton {
  display: inline;
  padding: 12px;
  margin: 8px;
  color:white;
}
.ResizeButton img {
  width: 28px;
  height: 28px;
}

#Canvas {
  width: 100%;
  position: relative;
  overflow: hidden;
}
#SingleCanvas{
  position: absolute;
  width: 100%;
}


.TileForeground {
  position: absolute;
  width: 1000px;
  height: 1000px;
  overflow: hidden;
  top: 0; bottom: 0; left: 0; right: 0;
  /* top: -50%;
  left:-50%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content:center;
}
.UnitTile{
  flex-basis: 5%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
}
.SingleForeground {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content:center;
  top: 0; bottom: 0; left: 0; right: 0;
}
#SingleContainer {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
}
#DragForeground {
  z-index: 999;
  position: absolute;
  width: 10000px;
  height: 10000px;
  overflow: hidden;
  top: 0; bottom: 0; left: 0; right: 0;
  cursor: move;
}
/* .background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0; bottom: 0; left: 0; right: 0;
} */
.ResizeButton {
  opacity: 0.3;
  padding: 10px;
  margin: 3px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.ResizeButton:hover {
  opacity: 0.6;
}
.ResizeButton.active {
  opacity: 1;
}

.ResizeButton h5{
  text-align: center;
  margin: 0px;
  /* font-family: aktiv-grotesk-extended; */
  letter-spacing: 0.03em;
  font-weight: 400;
  font-size: 11.5px;
}
.panel {
  min-width: 220px;
  max-width: 220px;
  font-size: 15px;
  font-weight: 400;
  padding: 16px 0px 16px;
  margin: 0px 0px 0px 20px;
  background-color: rgb(255, 255, 255);
}
.panel p {
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--black-80a);
  cursor: default;
  /* margin: 1px 0px 1px 0px; */
  margin: 0px;
}
.PanelSec{
  padding: 0px 16px;
  border-bottom: solid 1px rgb(235, 235, 235);
  padding-bottom: 16px;
}

.PanelSec.no-border{
  border-bottom: none;
  padding-bottom: 0px;

}

.PanelHeader{
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
}
.PanelUnitTitle{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px 6px 0px;
}
.PanelUnitTitle span{
  font-size: 14px;
  letter-spacing: 0.0em;
  color: rgb(156, 156, 156);
}
.PanelInputNum{
  font-size: 14px;
  letter-spacing: 0.0em;
  color: rgb(156, 156, 156);
  text-align: right;
  border: solid 0.5px transparent;
  padding: 5px 10px;
  margin: -5px -10px;
}
.PanelInputNum:hover{
  border: solid 0.5px rgb(228, 228, 228);
}
.PanelInstruction{
  font-size: 12px !important;
  color: rgb(119, 119, 119) !important;
  margin: 0px !important;
  margin-top: 20px !important;
}
.ColorSwatchGroup{
  display: flex;
  flex-flow: row wrap;
  width: 180px;
  justify-content: space-between;
}
.ColorSwatch{
  flex-basis: 13%;
  margin: 4px 2px;
  /* cursor: pointer; */
}
.FillModeContainer{
  padding: 12px 0px 0px 0px;
  border-bottom: solid 1px rgb(235, 235, 235);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.FillModeButton{
  font-size: 14.5px;
  font-weight: 500;
  color: rgb(143, 143, 143);
  padding: 10px 12px 7px 12px;
  border: none;
  transition: background-color 0.3s;
  cursor: pointer;
  width: 50%;
  text-align: center;
  margin-bottom: -1px;
}
.FillModeButton:hover{
  background-color: rgb(250, 250, 250);
  border-bottom: solid 4px rgb(223, 223, 223);

}
.FillModeButton.selected{
  color: var(--black-90);
  border-bottom: solid 4px var(--black-90);
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #e2e2e2;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 20px;
  background: var(--black-90);
  /* cursor: pointer; */
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 20px;
  background: var(--black-90);
  /* cursor: pointer; */
}
.ExportButtonContainer{
  position: relative;
  text-align: center;
}
#ExportButton{
  display: block;
  width:100%;
  color:white;
  background-color: var(--black-80a);
  border: none;
  padding: 16px 24px;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  /* text-transform: uppercase; */
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  /* font-family: aktiv-grotesk-extended; */
}
.ExportMenu{
  width: 100%;
  position: absolute;
  border: solid 1px rgb(235, 235, 235);
  background-color: white;
  display: block;
  transition: all 0.3s;
  margin-top: 10px;
}
.ExportMenu.hidden{
  display: none;
}
.ExportMenu a{
  font-size: 14px;
  display: block;
  padding: 16px 18px;
  text-decoration: none;
  cursor: pointer;
  color: var(--black-90);
  border-bottom: solid 0.5px rgb(233, 233, 233);
}
.ExportMenu a:hover{
  background-color: rgb(241, 241, 241);
}
/* .ExportMenuItem{
  padding: 12px 18px;
} */
/* #DownloadButton{
  text-decoration: none;
  color: var(--black-90)
} */


.App-link {
  color: rgba(0, 0, 0, 0.6);
}

.Footer {
  transition: transform 0.3s;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #2e2e2e;
  padding: 0px 30px 20px 30px;
  width: 100%;
}
.Footer.mini{
  transform: translateY(138px);
}
.FooterHead {
  color: rgb(255, 255, 255);

  display: flex;
  justify-content: space-between;
  align-items: center;
}
#FooterHeadExample{
  padding: 20px 0px 10px;

  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  width: 86%;
}
#FooterHeadExample:hover{
  opacity: 0.9;
}
.FooterMiniIcon{
  background-image: url(/arrow.svg);
  opacity: 0.8;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 12px;
  margin-left: 8px;
  transition: transform 0.5s;
  display: inline-block;
}
.FooterMiniIcon.mini{

  transform: rotate(180deg);
}
.Footer-text {
  font-family: aktiv-grotesk-extended;
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0px 12px 10px;
  color: rgb(255, 255, 255);
}

.FooterMore{
  margin: 0px 10px;
  cursor: pointer;
  font-size: 13px;
  opacity: 0.4;
  transition: opacity 0.2s;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding: 20px 0px 10px;
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.FooterMore:hover{
  opacity: 0.6;
}

.RegularFooter{
  padding-top: 20px;
}
.RegularFooterText{
  opacity: 0.7;
  margin-bottom: 0px;
  margin-top: 0px;
}
